<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      class="hidden-sm-and-down"
    >
      <base-img
        :src="require(`@/assets/firstvaultwhitebg.png`)"
        contain
        max-width="70"
        width="100%"
      />

      <v-spacer />

      <div>
        <v-tabs optional background-color="transparent">
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            <div v-if="name === 'Login'" class="d-flex flex-wrap">
              <base-btn to="/login">Login/Signup</base-btn>
            </div>
            <div v-else>
              {{ name }}
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </v-app-bar>
    <v-app-bar
      app
      dark
      elevation="1"
      elevate-on-scroll
      class="hidden-md-and-up black"
    >
      <base-img
        :src="require('@/assets/firstvaultblackbgg.png')"
        contain
        max-width="55"
        width="100%"
      />

      <v-spacer />

      <v-app-bar-nav-icon @click="drawer = !drawer" />
    </v-app-bar>

    <home-drawer v-model="drawer" :items="items" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeAppBar",

  components: {
    HomeDrawer: () => import("./Drawer"),
  },
  computed: {
    ...mapGetters(["StateUser", "StateAuthenticated", "StateEnterprise"]),
  },

  data: () => ({
    drawer: null,
    items: [
      "Home",
      //"About",
      "Features",
      //"Marketing",
      //"Gallery",
      "Pricing",
      "Resources",
      "Contact",
      "Login",
    ],
  }),
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
      &::before
        display: none

#v-app-bar
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important
</style>
